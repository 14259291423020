import type { LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { responseError } from "@tamarack/shared/errors";
import { tenantManagement } from "../menu-items/AdminMenuItems";
import { DEFAULT_PAGE_REDIRECT } from "../menu-items/lumber";
import { withAuth } from "../sessions.server";

export async function loader({ request, context }: LoaderFunctionArgs) {
  const { whoAmI } = await withAuth({ request, context });

  try {
    return redirect(
      whoAmI.admin ? tenantManagement.url.toString() : DEFAULT_PAGE_REDIRECT.toString()
    );
  } catch (e) {
    throw responseError("Could not load whoAmI", e);
  }
}

export default function IndexPage() {
  // This should never render. It's here so that the ErrorBoundary will render correctly.
  return <></>;
}
